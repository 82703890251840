import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap, map, takeUntil, combineLatestWith, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AppFacade } from '../../+state';
import { RouterFacade } from '../../router/+state';
import { AuthFacade } from '../+state';

@Injectable({ providedIn: 'root' })
export class AuthGuardOwnerService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private app: AppFacade,
    private router: RouterFacade,
    private auth: AuthFacade
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authGuard(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authGuard(next, state);
  }

  authGuard(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.idTokenResult$.pipe(
      combineLatestWith(this.auth.organizationRoles$),
      filter(([token, roles]) => !!(token && roles)),
      takeUntil(this.destroy$),
      tap(([token, roles]) => {
        if (token) {
          if (!roles || !roles.includes('owner')) {
            this.router.go({ path: ['account-error'] });
          }
        } else {
          this.app.setRedirectUrl(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map(([token, roles]) => !!token)
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
