import { Nav } from './models';

export const navElements: Nav[] = [
  {
    link: '',
    display: 'Admin',
    icon: 'supervisor_account',
    role: 'admin',
    parent: true,
    subMenu: [
      {
        link: '/admin/patients',
        display: 'Patients',
        icon: 'diversity_3',
        role: 'admin',
        parent: false,
      },
      {
        link: '/admin/users',
        display: 'Users',
        icon: 'manage_accounts',
        role: 'owner',
        parent: false,
      },
      {
        link: '/admin/logs',
        display: 'Logs',
        icon: 'list',
        role: 'qa-admin',
        parent: false,
      },
      // {
      //   link: '/users',
      //   display: 'Users',
      //   icon: 'account_box',
      //   role: 'marrick',
      //   parent: false,
      // },
    ],
  },
  {
    link: '/patients',
    display: 'Patients',
    icon: 'accessibility_new',
    role: 'user',
    parent: false,
  },
  {
    link: '/organizations',
    display: 'Organizations',
    icon: 'business',
    role: 'admin',
    parent: false,
  },
  {
    link: '',
    display: 'Reports',
    icon: 'analytics',
    role: 'qa-admin',
    parent: true,
    subMenu: [
      {
        link: '/reports/actions',
        display: 'Actions',
        icon: 'show_chart',
        role: 'qa-admin',
        parent: false,
      },
      // {
      //   link: '/users',
      //   display: 'Users',
      //   icon: 'account_box',
      //   role: 'marrick',
      //   parent: false,
      // },
    ],
  },
  //   {
  //     link: '/internal',
  //     display: 'Internal',
  //     icon: 'build_circle',
  //     role: 'marrick',
  //     parent: false,
  //   },
];
