// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAcePLc3GATRtjx9MybqkEYlGCGX6FSkCM',
    authDomain: 'artemis-4da86.firebaseapp.com',
    databaseURL: 'https://artemis-4da86.firebaseio.com',
    projectId: 'artemis-4da86',
    databaseId: 'athena',
    storageBucket: 'artemis-4da86.appspot.com',
    messagingSenderId: '714272847780',
    appId: '1:714272847780:web:b4c233c8098d1e06',
    measurementId: 'G-WH2WP8DZFS',
  },
  themeName: 'demo_theme',
  title: 'DEMO - Attorney Portal',
  apiRoot: 'https://api.gotoyap.com',
  entityType: '41c293a2-28af-11e9-ac23-12b06c210614',
  compiledPDFsRoot: '/s3/stream?bucket=documentgeneration&download=1&key=',
  // tslint:disable-next-line:max-line-length
  resetPasswordHTML: `You have requested to reset your password for the Portal.<br><br> <a href="https://demo.sharescape.io/password-reset?t=%s">Follow this link to reset your password.</a> <br><br>If you didn't request your password to be reset, you can ignore this email. <br><br>Kind Regards,<br><br>Portal Administrator`,
  organizationLogoUrl:
    'https://firebasestorage.googleapis.com/v0/b/artemis-4da86.appspot.com/o/artemis%2Flogos%2Fdummy-practice-logo.png?alt=media&token=893d2aa4-e5e1-4524-8661-c4fb04719ca3',

  logging: {
    disableConsoleLogging: true,
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.LOG,
    serverLoggingUrl: 'https://api.gotoyap.com/artemis/logs?database=athena',
  },
  environment: 'DEMO',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
