/* src/app/core/components/choose-filename/choose-filename.component.scss */
.content {
  display: flex;
  width: 80vw;
  max-width: 400px;
}
.flex-item {
  width: 100%;
}
.footer {
  display: flex;
  justify-content: flex-end;
}
/*# sourceMappingURL=choose-filename.component.css.map */
