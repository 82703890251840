import * as actions from './app.actions';
import {
  USState,
  Prefix,
  DocType,
  PortalConfiguration,
  PhoneType,
  TransactionType,
  Nav,
  DocumentGeneration,
} from '../models';

import { navElements } from '../app.nav';

export interface State {
  navElements: Nav[];
  states: USState[];
  prefixes: Prefix[];
  docTypes: DocType[];
  themeName: string;
  documentGeneration: DocumentGeneration[];
  transactionTypes: TransactionType[];
  displayedColumns: { [key: string]: Array<string> };
  phoneTypes: PhoneType[];
  redirectUrl: string;
  privacyPolicy: string;
  termsOfService: string;
  apiRoot: string;
  organizationLogoUrl: string;
  environment: string;
  entityType: string;
  resetPasswordHTML: string;
  compiledPDFsRoot: string;
  contact: string;
  requestAppointment: string;
  portalConfiguration: PortalConfiguration;
  patientImport: boolean;
  patientUploadDocuments: boolean;
  documentsRefresh: boolean;
  documentsLayout: string;
  refreshPatient: boolean;
  removePatient: boolean;
  error: any;
}
export const initialState: State = {
  navElements: [],
  states: [],
  prefixes: [],
  docTypes: [],
  documentGeneration: [],
  transactionTypes: [],
  themeName: '',
  displayedColumns: null,
  phoneTypes: [],
  privacyPolicy: '',
  termsOfService: '',
  redirectUrl: '',
  apiRoot: '',
  organizationLogoUrl: '',
  environment: '',
  entityType: '',
  resetPasswordHTML: '',
  compiledPDFsRoot: '',
  contact: '',
  requestAppointment: '',
  portalConfiguration: null,
  patientImport: false,
  patientUploadDocuments: false,
  documentsRefresh: false,
  documentsLayout: 'Table',
  refreshPatient: false,
  removePatient: false,
  error: null,
};

export function reducer(
  state: State = initialState,
  action: actions.ActionsUnion
): State {
  switch (action.type) {
    case actions.ActionTypes.SetApiRoot: {
      return { ...state, apiRoot: action.payload };
    }

    case actions.ActionTypes.SetOrganizationLogoUrl: {
      return { ...state, organizationLogoUrl: action.payload };
    }

    case actions.ActionTypes.SetEnvironment: {
      return { ...state, environment: action.payload };
    }

    case actions.ActionTypes.SetEntityType: {
      return { ...state, entityType: action.payload };
    }

    case actions.ActionTypes.SetResetPasswordHTML: {
      return { ...state, resetPasswordHTML: action.payload };
    }

    case actions.ActionTypes.SetCompiledPDFsRoot: {
      return { ...state, compiledPDFsRoot: action.payload };
    }

    case actions.ActionTypes.SetRedirectUrl: {
      return { ...state, redirectUrl: action.payload };
    }

    case actions.ActionTypes.LoadStatesSuccess: {
      const states = [];
      action.payload.forEach((obj) => {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            states.push({
              abbreviation: prop,
              name: obj[prop],
            });
          }
        }
      });
      return {
        ...state,
        states,
      };
    }
    case actions.ActionTypes.LoadPrefixesSuccess: {
      return {
        ...state,
        prefixes: action.payload,
      };
    }
    case actions.ActionTypes.LoadDocTypesSuccess: {
      return { ...state, docTypes: action.payload };
    }

    case actions.ActionTypes.LoadDocumentGenerationSuccess: {
      return { ...state, documentGeneration: action.payload };
    }

    case actions.ActionTypes.LoadTransactionTypesSuccess: {
      return { ...state, transactionTypes: action.payload };
    }

    case actions.ActionTypes.LoadDisplayColumnsSuccess: {
      return { ...state, displayedColumns: action.payload };
    }

    case actions.ActionTypes.LoadPhoneTypesSuccess: {
      const phoneTypes = [];
      action.payload.forEach((obj) => {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            phoneTypes.push({
              id: prop,
              description: obj[prop],
            });
          }
        }
      });

      return {
        ...state,
        phoneTypes,
      };
    }

    case actions.ActionTypes.GetPortalConfigSuccess: {
      return {
        ...state,
        portalConfiguration: action.payload,
        navElements: navElements,
        patientImport: action.payload.patientImport,
        patientUploadDocuments: action.payload.patientUploadDocuments,
        displayedColumns: action.payload.displayedColumns,
        documentsRefresh: action.payload.documentsRefresh,
        documentsLayout: action.payload.documentsLayout,
        refreshPatient: action.payload.refreshPatient,
        removePatient: action.payload.removePatient,
        privacyPolicy: action.payload.privacyPolicy,
        termsOfService: action.payload.termsOfService,
        requestAppointment: action.payload.requestAppointment,
        contact: action.payload.contact,
      };
    }

    case actions.ActionTypes.LoadStatesError:
    case actions.ActionTypes.LoadPrefixesError:
    case actions.ActionTypes.LoadPhoneTypesError:
    case actions.ActionTypes.GetPortalConfigError: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case actions.ActionTypes.SetThemeName: {
      // Persist the Theme to LocalStorage
      localStorage.setItem('theme', action.payload);
      return { ...state, themeName: action.payload };
    }

    case actions.ActionTypes.ClearState: {
      return {
        ...state,
        navElements: [],
        states: [],
        prefixes: [],
        docTypes: [],
        transactionTypes: [],
        displayedColumns: null,
        phoneTypes: [],
        redirectUrl: '',
        portalConfiguration: null,
        patientUploadDocuments: false,
        refreshPatient: false,
        removePatient: false,
        requestAppointment: '',
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
