import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromReducer from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromReducer.State>('auth');

export const selectAuthenticated = createSelector(
  selectAuthState,
  fromReducer.getAuthenticated
);

export const selectUid = createSelector(selectAuthState, fromReducer.getUid);

export const selectFirebaseToken = createSelector(
  selectAuthState,
  fromReducer.getFirebaseToken
);

export const selectFirebaseUser = createSelector(
  selectAuthState,
  fromReducer.getFirebaseUser
);

export const selectRoles = createSelector(
  selectAuthState,
  fromReducer.getRoles
);
export const selectOrganizationId = createSelector(
  selectAuthState,
  fromReducer.getOrganizationId
);
export const selectOrganizations = createSelector(
  selectAuthState,
  fromReducer.getOrganizations
);
export const selectOrganization = createSelector(
  selectAuthState,
  fromReducer.getOrganization
);
export const selectCredentials = createSelector(
  selectAuthState,
  fromReducer.getCredentials
);

/**
 * Returns true if the user has an admin role in any organization
 */
export const selectHasAdminRole = createSelector(selectCredentials, (creds) => {
  return (
    creds &&
    creds.roles &&
    Object.keys(creds.roles).some((orgId) => {
      return creds.roles[orgId].includes('admin');
    })
  );
});

/**
 * Returns the roles for the current organization
 */
export const selectOrganizationRoles = createSelector(
  selectCredentials,
  selectOrganization,
  (creds, org) => {
    if (!org || !creds) {
      return null;
    }
    return creds.roles[org.id] ?? [];
  }
);
export const selectOrganizationName = createSelector(
  selectOrganization,
  (org) => {
    return org && org.name;
  }
);
export const selectOrganizationLogoUrl = createSelector(
  selectOrganization,
  (org) => {
    return org && org.logoUrl;
  }
);

export const selectOrganizationUrl = createSelector(
  selectOrganization,
  (org) => {
    return org && org.url;
  }
);

// export const selectOrganizationContact = createSelector(
//   selectOrganization,
//   org => {
//     return org && org.contact;
//   }
// );

// export const selectOrganizationPrivacyPolicy = createSelector(
//   selectOrganization,
//   org => {
//     return org && org.privacyPolicy;
//   }
// );
// export const selectOrganizationFeedbackMessage = createSelector(
//   selectOrganization,
//   org => {
//     return org && org.feedbackMessage;
//   }
// );
// export const selectOrganizationFeedbackRecipients = createSelector(
//   selectOrganization,
//   org => {
//     return org && org.feedbackRecipients;
//   }
// );

// export const selectOrganizationTermsOfService = createSelector(
//   selectOrganization,
//   org => {
//     return org && org.termsOfService;
//   }
// );

export const selectPhotoUrl = createSelector(
  selectAuthState,
  fromReducer.getPhotoUrl
);
