import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AppFacade } from '../../+state';
import { RouterFacade } from '../../router/+state';

@Injectable({ providedIn: 'root' })
export class AuthGuardQaAdminService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private app: AppFacade,
    private router: RouterFacade
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.idTokenResult$.pipe(
      takeUntil(this.destroy$),
      tap((token) => {
        if (token) {
          if (
            token.claims.roles &&
            !(token.claims.roles as string[]).includes('qa-admin')
          ) {
            this.router.go({ path: ['account-error'] });
          }
        } else {
          this.app.setRedirectUrl(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.idTokenResult$.pipe(
      takeUntil(this.destroy$),
      tap((token) => {
        if (token) {
          if (
            token.claims.roles &&
            !(token.claims.roles as string[]).includes('qa-admin')
          ) {
            this.router.go({ path: ['account-error'] });
          }
        } else {
          this.app.setRedirectUrl(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((token) => !!token)
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
