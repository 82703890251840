import { Credential, Organization } from 'src/app/models';
import * as actions from './auth.actions';
import { User } from '@angular/fire/auth';

export interface State {
  authenticated: boolean;
  uid: string;
  firebaseToken: string | null;
  firebaseUser: User | null;
  userCredentials: Credential | null;
  roles: Array<string>;
  organizationId: string;
  organization: Organization;
  organizations: Array<Organization>;
  photoUrl: string;
  error: string | null;
}
export const initialState: State = {
  authenticated: false,
  uid: null,
  roles: [],
  organizationId: '',
  organization: null,
  organizations: [],
  photoUrl: '',
  firebaseToken: null,
  firebaseUser: null,
  userCredentials: null,
  error: null,
};

export function reducer(
  state: State = initialState,
  action: actions.ActionsUnion
) {
  switch (action.type) {
    case actions.ActionTypes.SetAuthentication: {
      return {
        ...state,
        authenticated: action.payload,
      };
    }
    case actions.ActionTypes.SetFirebaseToken: {
      return { ...state, firebaseToken: action.payload };
    }
    case actions.ActionTypes.SetFirebaseUser: {
      return {
        ...state,
        firebaseUser: action.payload,
      };
    }
    case actions.ActionTypes.LoadCredentialsSuccess: {
      return {
        ...state,
        userCredentials: action.payload,
      };
    }
    case actions.ActionTypes.SetUid: {
      return { ...state, uid: action.payload };
    }
    case actions.ActionTypes.SetPhotoUrl: {
      return { ...state, photoURL: action.payload };
    }
    case actions.ActionTypes.SetRoles: {
      return { ...state, roles: action.payload };
    }
    case actions.ActionTypes.SetOrganizationId: {
      return { ...state, organizationId: action.payload };
    }
    case actions.ActionTypes.SetOrganization: {
      return { ...state, organization: action.payload };
    }
    case actions.ActionTypes.SetOrganizations: {
      return { ...state, organizations: action.payload };
    }
    case actions.ActionTypes.LogoutSuccess: {
      return { ...initialState };
    }
    case actions.ActionTypes.LoginError:
    case actions.ActionTypes.LogoutError:
    case actions.ActionTypes.ResetPasswordRequestError:
    case actions.ActionTypes.AdminResetPasswordError:
    case actions.ActionTypes.ResetPasswordError: {
      return { ...state, error: action.payload };
    }

    case actions.ActionTypes.Error: {
      return { ...state, error: { ...action.payload } };
    }

    case actions.ActionTypes.ClearState: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export const getUid = (state: State) => state.uid;
export const getFirebaseToken = (state: State) => state.firebaseToken;
export const getFirebaseUser = (state: State) => state.firebaseUser;
export const getRoles = (state: State) => state.roles;
export const getOrganizationId = (state: State) => state.organizationId;
export const getOrganization = (state: State) => state.organization;
export const getOrganizations = (state: State) => state.organizations;
export const getPhotoUrl = (state: State) => state.photoUrl;
export const getAuthenticated = (state: State) => state.authenticated;
export const getError = (state: State) => state.error;
export const getCredentials = (state: State) => state.userCredentials;
