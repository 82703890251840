import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { SnackBarInfo } from 'src/app/models';

@Component({
  selector: 'marrick-snackbar-error',
  template: `
    <div class="container">
      <div class="message">
        <span class="snack-bar-success">{{ data.message }}</span>
        <br />
        <span class="snack-bar-success" *ngIf="data.errorCode">
          Contact Administrator. Code: {{ data.errorCode }}
        </span>
      </div>
      <button
        *ngIf="data.errorCode"
        mat-button
        color="warn"
        ngxClipboard
        [cbContent]="getMessage()"
        (click)="snackBarRef.dismiss()"
      >
        Copy
      </button>
      <button
        *ngIf="!data.errorCode"
        mat-button
        color="warn"
        (click)="snackBarRef.dismiss()"
      >
        OK
      </button>
      <button mat-icon-button (click)="snackBarRef.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        flex-direction: row;
      }
      .message {
        padding-right: 15px;
      }
      .snack-bar-success {
        font-size: 0.9em;
      }
    `,
  ],
})
export class SnackBarErrorComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarInfo
  ) {}

  getMessage(): string {
    if (this.data.errorCode) {
      return `${this.data.message} Contact Administrator. Code: ${this.data.errorCode}`;
    }
    return this.data.message;
  }
}
