import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromReducer from './app.reducer';
import * as fromActions from './app.actions';
import * as fromSelectors from './app.selectors';
import {
  USState,
  Prefix,
  DocType,
  PhoneType,
  PortalConfiguration,
  SnackBarInfo,
  TransactionType,
  Nav,
  DocumentGeneration,
} from '../models';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  apiRoot$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectApiRoot)
  );
  organizationLogoUrl$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationLogoUrl)
  );
  entityType$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectEntityType)
  );
  environment$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectEnvironment)
  );
  navElements$: Observable<Nav[]> = this.store.pipe(
    select(fromSelectors.selectNavElements)
  );
  portalConfiguration$: Observable<PortalConfiguration> = this.store.pipe(
    select(fromSelectors.selectPortalConfiguration)
  );
  portalConfigurationId$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectPortalConfigurationId)
  );
  patientImport$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectPatientImport)
  );
  patientUploadDocuments$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectPatientUploadDocuments)
  );
  privacyPolicy$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectPrivacyPolicy)
  );
  themeName$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectThemeName)
  );
  documentsRefresh$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectDocumentsRefresh)
  );
  patientRefresh$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectPatientRefresh)
  );
  patientRemove$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectPatientRemove)
  );
  documentsLayout$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectDocumentsLayout)
  );
  states$: Observable<Array<USState>> = this.store.pipe(
    select(fromSelectors.selectStates)
  );
  prefixes$: Observable<Array<Prefix>> = this.store.pipe(
    select(fromSelectors.selectPrefix)
  );
  docTypes$: Observable<Array<DocType>> = this.store.pipe(
    select(fromSelectors.selectDocTypes)
  );
  documentGeneration$: Observable<Array<DocumentGeneration>> = this.store.pipe(
    select(fromSelectors.selectDocumentGeneration)
  );
  transactionTypes$: Observable<Array<TransactionType>> = this.store.pipe(
    select(fromSelectors.selectTransactionTypes)
  );
  termsOfService$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectTermsOfService)
  );
  contact$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectContact)
  );
  requestAppointment$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectRequestAppointment)
  );
  phoneTypes$: Observable<Array<PhoneType>> = this.store.pipe(
    select(fromSelectors.selectPhoneTypes)
  );

  redirectUrl$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectRedirectUrl)
  );
  resetPasswordHTML$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectResetPasswordHTML)
  );

  compiledPDFsRoot$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectCompiledPDFsRoot)
  );

  displayedColumns$(props: Object): Observable<Array<string>> {
    return this.store.pipe(select(fromSelectors.selectDisplayedColumns, props));
  }

  url$: Observable<string> = this.store.pipe(select(fromSelectors.selectUrl));

  constructor(private store: Store<fromReducer.State>) {}

  loadApiRoot(apiRoot: string) {
    this.store.dispatch(new fromActions.SetApiRootAction(apiRoot));
  }

  loadOrganizationLogoUrl(organizationLogoUrl: string) {
    this.store.dispatch(
      new fromActions.SetOrganizationLogoUrlAction(organizationLogoUrl)
    );
  }

  loadThemeName(themeName: string) {
    this.store.dispatch(new fromActions.SetThemeNameAction(themeName));
  }

  loadEnvironment(env: string) {
    this.store.dispatch(new fromActions.SetEnvironmentAction(env));
  }

  loadEntityType(entityType: string) {
    this.store.dispatch(new fromActions.SetEntityTypeAction(entityType));
  }

  loadPortalConfiguration() {
    this.store.dispatch(new fromActions.GetPortalConfigAction());
  }

  loadResetPasswordHTML(resetPasswordHTML: string) {
    this.store.dispatch(
      new fromActions.SetResetPasswordHTMLAction(resetPasswordHTML)
    );
  }

  loadCompiledPDFsRoot(pdfsRoot: string) {
    this.store.dispatch(new fromActions.SetCompiledPDFsRootAction(pdfsRoot));
  }

  loadStates() {
    this.store.dispatch(new fromActions.LoadStatesAction());
  }

  loadPrefixes() {
    this.store.dispatch(new fromActions.LoadPrefixesAction());
  }

  loadPhoneTypes() {
    this.store.dispatch(new fromActions.LoadPhoneTypesAction());
  }

  setRedirectUrl(url: string) {
    this.store.dispatch(new fromActions.SetRedirectUrlAction(url));
  }
  showSnackbar(snackBarInfo: SnackBarInfo) {
    this.store.dispatch(new fromActions.ShowSnackbarAction(snackBarInfo));
  }
  showSnackbarError(snackBarInfo: SnackBarInfo) {
    this.store.dispatch(new fromActions.ShowSnackbarErrorAction(snackBarInfo));
  }
}
