/* angular:styles/component:css;9b0ac6dfb0b41696202f1dd76a751810ba8216b09f69f9436aa73a2db8ab363d;/home/runner/work/tao/tao/ui/src/app/core/services/snackbar/snackbar-error.component.ts */
.container {
  display: flex;
  flex-direction: row;
}
.message {
  padding-right: 15px;
}
.snack-bar-success {
  font-size: 0.9em;
}
/*# sourceMappingURL=snackbar-error.component.css.map */
