/* src/app/core/components/drop-zone/drop-zone.component.scss */
.dropZone {
  display: flex;
  position: relative;
  overflow: auto;
  justify-content: center;
  align-items: center;
  cursor: copy;
  border-radius: 4px;
  border: 2px dotted silver;
  height: 100%;
  width: 100%;
  transition: all 1s ease;
}
.drop-zone-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input {
  display: none;
}
/*# sourceMappingURL=drop-zone.component.css.map */
