import { Injectable } from '@angular/core';
import { AppFacade } from '../../../+state';
import { LoggerService } from '../logger/logger.service';
import { createUniqueCode } from '../../utils/unique-id';
import { SnackBarInfo } from '../../../models';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private app: AppFacade, private logger: LoggerService) {}

  showErrorMessage(uid: string, message: string, err: any) {
    const errorCode = createUniqueCode();
    const snackBarInfo: SnackBarInfo = {
      message,
      duration: 0,
      errorCode,
    };
    this.logger.error(
      `UID: ${uid} ErrorCode: ${errorCode} ${JSON.stringify(
        err,
        Object.getOwnPropertyNames(err)
      )}`
    );
    this.app.showSnackbarError(snackBarInfo);
  }
}
