import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AppFacade } from '../../../+state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements OnDestroy {
  environment: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private logger: NGXLogger, private app: AppFacade) {
    this.app.environment$
      .pipe(takeUntil(this.destroy$))
      .subscribe((env) => (this.environment = env));
  }

  trace(message, ...additonal: Array<any>): void {
    this.logger.trace(
      `[${this.environment}] ${message} {${navigator.userAgent}} ${additonal}`
    );
  }

  error(message, ...additonal: Array<any>): void {
    this.logger.error(
      `[${this.environment}] ${message} {${navigator.userAgent}} ${additonal}`
    );
  }

  warn(message, ...additonal: Array<any>): void {
    this.logger.warn(
      `[${this.environment}] ${message} {${navigator.userAgent}} ${additonal}`
    );
  }
  log(message, ...additonal: Array<any>): void {
    this.logger.log(
      `[${this.environment}] ${message} {${navigator.userAgent}} ${additonal}`
    );
  }

  debug(message, ...additonal: Array<any>): void {
    this.logger.debug(
      `[${this.environment}] ${message} {${navigator.userAgent}} ${additonal}`
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
