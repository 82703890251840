import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackBarInfo } from '../../../models';
import { SnackBarErrorComponent } from './snackbar-error.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  getConfig(snackBar: SnackBarInfo): MatSnackBarConfig {
    const horizontal: MatSnackBarHorizontalPosition =
      snackBar.horizontalPosition
        ? <MatSnackBarHorizontalPosition>snackBar.horizontalPosition
        : 'left';
    const vertical: MatSnackBarVerticalPosition = snackBar.verticalPosition
      ? <MatSnackBarVerticalPosition>snackBar.verticalPosition
      : 'bottom';
    const config: MatSnackBarConfig = {
      duration: snackBar.duration,
      panelClass: snackBar.style,
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      data: {
        message: snackBar.message,
        errorCode: snackBar.errorCode,
      },
    };
    return config;
  }

  showMessage(message: string, action = 'OK', duration = 5000) {
    const snackbar: SnackBarInfo = {
      message,
      action,
      duration,
    };

    this.showSnackBar(snackbar);
  }

  showError(message: string, action = 'OK', duration = 10000) {
    const snackbar: SnackBarInfo = {
      message,
      action,
      duration,
    };

    this.showSnackBarError(snackbar);
  }

  showSnackBar(snackBar: SnackBarInfo) {
    this.snackBar.open(
      snackBar.message,
      snackBar.action,
      this.getConfig(snackBar)
    );
  }

  showSnackBarError(snackBar: SnackBarInfo) {
    return this.snackBar.openFromComponent(
      SnackBarErrorComponent,
      this.getConfig(snackBar)
    );
  }
}
