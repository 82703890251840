import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import {
  map,
  tap,
  take,
  mergeMap,
  expand,
  takeWhile,
  first,
} from 'rxjs/operators';
import {
  Firestore,
  collection,
  doc,
  query,
  orderBy,
  limit,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  collectionData,
  docSnapshots,
  collectionChanges,
  writeBatch,
  serverTimestamp,
  onSnapshot,
  DocumentReference,
  CollectionReference,
  Query,
  QueryConstraint,
  DocumentChange,
  QuerySnapshot,
  GeoPoint,
} from '@angular/fire/firestore';

type CollectionPredicate<T> = string | CollectionReference<T>;
type DocPredicate<T> = string | DocumentReference<T>;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore) {}

  /// **************
  /// Get a Reference
  /// **************

  col<T>(
    ref: CollectionPredicate<T>,
    ...queryConstraints: QueryConstraint[]
  ): Query<T> | CollectionReference<T> {
    if (typeof ref === 'string') {
      const collectionRef = collection(
        this.firestore,
        ref
      ) as CollectionReference<T>;
      return queryConstraints.length
        ? query(collectionRef, ...queryConstraints)
        : collectionRef;
    } else {
      return queryConstraints.length ? query(ref, ...queryConstraints) : ref;
    }
  }

  doc<T>(ref: DocPredicate<T>): DocumentReference<T> {
    return typeof ref === 'string'
      ? (doc(this.firestore, ref) as DocumentReference<T>)
      : ref;
  }

  /// **************
  /// Check if doc exists
  /// **************
  docExists$<T>(path: string): Observable<T | undefined> {
    const docRef = doc(this.firestore, path);
    return docSnapshots(docRef).pipe(
      first(),
      map((snapshot) => snapshot.data())
    ) as Observable<T | undefined>;
  }

  /// **************
  /// Get Data
  /// **************

  // Get document without document ID
  docWithoutId$<T>(ref: DocPredicate<T>): Observable<T | undefined> {
    const docRef = this.doc(ref);
    return docSnapshots(docRef).pipe(
      map((snapshot) => snapshot.data() as T | undefined)
    );
  }

  // Get document with document ID
  doc$<T>(ref: DocPredicate<T>): Observable<(T & { id: string }) | undefined> {
    const docRef = this.doc(ref);
    return docSnapshots(docRef).pipe(
      map((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data() as T;
          const id = snapshot.id;
          return { id, ...data };
        } else {
          return undefined;
        }
      })
    );
  }

  // Get an array of Observable documents Collection without IDs
  colWithoutIds$<T>(
    ref: CollectionPredicate<T>,
    ...queryConstraints: QueryConstraint[]
  ): Observable<T[]> {
    const collectionRef = this.col(ref, ...queryConstraints);
    return collectionData(collectionRef) as Observable<T[]>;
  }

  // Get an array of Observable documents by Collection with IDs
  col$<T>(
    ref: CollectionPredicate<T>,
    ...queryConstraints: QueryConstraint[]
  ): Observable<(T & { id: string })[]> {
    const collectionRef = this.col(ref, ...queryConstraints);
    return collectionData(collectionRef, {
      idField: 'id' as keyof T,
    }) as Observable<(T & { id: string })[]>;
  }

  colStateChanges$<T>(
    ref: CollectionPredicate<T>,
    ...queryConstraints: QueryConstraint[]
  ): Observable<DocumentChange<T>> {
    const collectionRef = this.col(ref, ...queryConstraints);
    return collectionChanges(collectionRef).pipe(
      mergeMap((actions) => actions)
    );
  }

  /// **************
  /// Get Collection Snapshots
  /// **************

  colSnapshots$<T>(
    ref: CollectionPredicate<T>,
    ...queryConstraints: QueryConstraint[]
  ): Observable<QuerySnapshot<T>> {
    const collectionRef = this.col(ref, ...queryConstraints) as Query<T>;
    return new Observable<QuerySnapshot<T>>((observer) => {
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => observer.next(snapshot),
        (error) => observer.error(error)
      );
      return { unsubscribe };
    });
  }

  /// **************
  /// Write Data
  /// **************

  // Firebase Server Timestamp
  get timestamp() {
    return serverTimestamp();
  }

  // Generate a Firestore ID
  get id() {
    return doc(collection(this.firestore, '_')).id;
  }

  // Replace or Add a Document
  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    const docRef = this.doc(ref);
    return setDoc(docRef, {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  // Update an existing Document
  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    const docRef = this.doc(ref);
    return updateDoc(docRef, {
      ...data,
      updatedAt: timestamp,
    });
  }

  // Delete an existing Document
  delete<T>(ref: DocPredicate<T>): Promise<void> {
    const docRef = this.doc(ref);
    return deleteDoc(docRef);
  }

  // Add a new Document with the pattern of createdAt = updatedAt
  add<T>(ref: CollectionPredicate<T>, data: T): Promise<DocumentReference<T>> {
    const timestamp = this.timestamp;
    const collectionRef = this.col(ref) as CollectionReference<T>;
    return addDoc(collectionRef, {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  // Add a new Document with a generated ID
  addWithId<T>(ref: CollectionPredicate<T>, data): Promise<void> {
    const timestamp = this.timestamp;
    const id = this.id;
    const collectionRef = this.col(ref) as CollectionReference<T>;
    const docRef = doc(collectionRef, id);
    return setDoc(docRef, {
      ...data,
      id: id,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  // Add a new Document with a given ID
  addWithGivenId<T>(ref: CollectionPredicate<T>, data): Promise<void> {
    const timestamp = this.timestamp;
    const collectionRef = this.col(ref) as CollectionReference<T>;
    const docRef = doc(collectionRef, data.id);
    return setDoc(docRef, {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  geopoint(lat: number, lng: number) {
    return new GeoPoint(lat, lng);
  }

  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const docRef = this.doc(ref);
    return getDoc(docRef).then((snapshot) => {
      if (snapshot.exists()) {
        return this.update(ref, data);
      } else {
        return this.set(ref, data);
      }
    });
  }

  upsert$<T>(ref: DocPredicate<T>, data: any): Observable<any> {
    const docRef = this.doc(ref);
    return docSnapshots(docRef).pipe(
      take(1),
      mergeMap((snapshot) =>
        snapshot.exists()
          ? from(this.update(ref, data))
          : from(this.set(ref, data))
      )
    );
  }

  update$<T>(ref: DocPredicate<T>, data: any): Observable<void> {
    const timestamp = this.timestamp;
    const docRef = this.doc(ref);
    return from(
      updateDoc(docRef, {
        ...data,
        updatedAt: timestamp,
      })
    );
  }

  /// **************
  /// Inspect Data
  /// **************

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    const docRef = this.doc(ref);
    docSnapshots(docRef)
      .pipe(
        take(1),
        tap((snapshot) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, snapshot);
        })
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    const collectionRef = this.col(ref);
    from(getDocs(collectionRef))
      .pipe(
        tap((snapshot) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, snapshot.docs);
        })
      )
      .subscribe();
  }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, docRef: DocPredicate<any>) {
    const hostDocRef = this.doc(host);
    const connectedDocRef = this.doc(docRef);
    return updateDoc(hostDocRef, { [key]: connectedDocRef });
  }

  /// returns a document's references mapped to DocumentReference
  docWithRefs$<T>(ref: DocPredicate<T>): Observable<any> {
    return this.doc$<T>(ref).pipe(
      map((doc) => {
        if (doc) {
          for (const k of Object.keys(doc)) {
            if (doc[k] instanceof DocumentReference) {
              doc[k] = this.doc(doc[k].path);
            }
          }
        }
        return doc;
      })
    );
  }

  /// **************
  /// Atomic batch example
  /// **************

  /// Just an example, you will need to customize this method.
  atomic() {
    const batch = writeBatch(this.firestore);

    const itemDocRef = doc(this.firestore, 'items/myCoolItem');
    const userDocRef = doc(this.firestore, 'users/userId');

    const currentTime = this.timestamp;

    batch.update(itemDocRef, { timestamp: currentTime });
    batch.update(userDocRef, { timestamp: currentTime });

    /// commit operations
    return batch.commit();
  }

  /**
   * Delete a collection, in batches of batchSize. Note that this does
   * not recursively delete subcollections of documents in the collection.
   */
  deleteCollection(path: string, batchSize: number): Observable<any> {
    return this.deleteBatch(path, batchSize).pipe(
      expand((val) => (val > 0 ? this.deleteBatch(path, batchSize) : of(0))),
      takeWhile((val) => val > 0)
    );
  }

  // Deletes documents as batched transaction
  private deleteBatch(path: string, batchSize: number): Observable<number> {
    const collectionRef = collection(this.firestore, path);
    const q = query(collectionRef, orderBy('__name__'), limit(batchSize));
    return from(getDocs(q)).pipe(
      mergeMap((snapshot) => {
        if (snapshot.size === 0) {
          return of(0);
        }
        const batch = writeBatch(this.firestore);
        snapshot.docs.forEach((docSnapshot) => {
          batch.delete(docSnapshot.ref);
        });
        return from(batch.commit()).pipe(map(() => snapshot.size));
      })
    );
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r =
        (crypto.getRandomValues(new Uint8Array(1))[0] & 15) >>
        (c === 'x' ? 0 : 0);
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
