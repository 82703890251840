import { Injectable, OnDestroy, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject, from } from 'rxjs';
import {
  filter,
  takeUntil,
  tap,
  catchError,
  switchMap,
  map,
} from 'rxjs/operators';
import { LayoutFacade } from '../../layout/+state';
import { AppFacade } from '../../+state';
import { AuthFacade } from '../+state';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggerService } from '../../core/services';
import {
  LoginCredential,
  UserCredential,
  Credential,
  ChangePasswordCredential,
  ResetPasswordRequest,
  ResetPassword,
  PortalConfiguration,
  Patient,
  ApiAdminUserRequest,
  ApiAdminUserResponse,
} from '../../models';
import { environment } from '../../../environments/environment';
import { Analytics, isSupported, logEvent } from '@angular/fire/analytics';
import {
  Auth,
  authState,
  idToken,
  getIdTokenResult,
  signInWithCustomToken,
  user,
  IdTokenResult,
  User,
  signOut,
} from '@angular/fire/auth';

export interface ApiResponse {
  data: any;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // Create an Observable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Store the API Root address in a subscription from the store
  apiRoot: string;
  firebaseToken: string;
  entityType: string;
  portalConfig: PortalConfiguration;

  // Currently logged in user.
  uid: string;

  // Expose the Firebase Token Observable
  fbAuth: Auth = inject(Auth);
  authState$: Observable<User>;
  idToken$: Observable<string>;

  user$: Observable<User>;
  idTokenResult$: BehaviorSubject<IdTokenResult> = new BehaviorSubject(null);

  analytics: Analytics;

  constructor(
    private router: Router,
    private auth: AuthFacade,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private app: AppFacade,
    private logger: LoggerService,
    private injector: Injector
  ) {
    isSupported().then((r) => {
      if (r) {
        this.analytics = this.injector.get(Analytics);
      }
    });
    this.authState$ = authState(this.fbAuth);
    this.idToken$ = idToken(this.fbAuth);
    this.user$ = user(this.fbAuth);
    this.user$.subscribe((user) => {
      if (user) {
        getIdTokenResult(user).then((res) => {
          this.idTokenResult$.next(res);
        });
      }
    });

    // Get the API Root, Entity Type and Firebase Token from the Store.
    this.app.apiRoot$.pipe(takeUntil(this.destroy$)).subscribe((api) => {
      this.apiRoot = api;
    });

    this.app.entityType$
      .pipe(
        filter((token) => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe((entity) => (this.entityType = entity));

    this.app.portalConfiguration$
      .pipe(
        filter((token) => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe((config) => (this.portalConfig = config));

    this.idToken$
      .pipe(
        filter((token) => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe((token) => (this.firebaseToken = token));

    this.auth.uid$
      .pipe(
        filter((uid) => !!uid),
        takeUntil(this.destroy$)
      )
      .subscribe((uid) => (this.uid = uid));
  }

  // Login with username and password.
  login(credentials: LoginCredential): Observable<any> {
    const params = new HttpParams().set(
      'database_id',
      environment.firebaseConfig.databaseId
    );
    return this.http.post<ApiResponse>(
      `${this.apiRoot}/authenticate/user`,
      credentials,
      { params }
    );
  }
  // Get Firebase custom token from API passing in valid JWT
  getFirebaseCustomToken(jwt: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `${jwt}`);
    return this.http.get<ApiResponse>(`${this.apiRoot}/artemis/token`, {
      headers,
    });
  }

  // Authenticate with Firebase passing in custom token
  firebaseAuth(mintedToken): Observable<any> {
    return from(
      signInWithCustomToken(this.fbAuth, mintedToken).then((res) => {
        return this.logger.log(`UID: ${res.user.uid} successfully logged in.`);
      })
    );
  }

  switchOrganization(orgId: string): Observable<any> {
    const headers = this.createApiTokenHeaders();
    const params = new HttpParams().set('org_id', orgId);

    logEvent(this.analytics, 'Switch Organization', {
      uid: this.uid,
      organizationId: orgId,
    });

    this.logger.log(`UID: ${this.uid} switching to Organization: ${orgId}.`);
    return this.http.get<ApiResponse>(`${this.apiRoot}/artemis/token`, {
      headers: headers,
      params: params,
    });
  }

  /**
   * createApiUser(user)
   *
   * 1. This function expects a fully populated User object.
   * 2. Manufacture the body for the API in the following shape;
   *    {
   *       "email": Username and Email share the same value,
   *       "first_name": First Name,
   *       "last_name": Last Name,
   *       "password": Password in clear text,
   *       "primary_phone": string,
   *       "active": boolean
   *       "group_ids": Array of API group ids
   *     }
   * 3. POST to the API_ROOT/user endpoint sending the body object
   * 3. The endpoint will accept either an API JWT or a Firebase JWT
   * 4. If successful, dispatch an Action to create a credentials document listing the
   *    new user's roles
   */
  createApiUser(user: UserCredential): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
    };

    return this.http
      .get<ApiResponse>(
        `${this.apiRoot}/artemis/user-access/random-password`,
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          const newUser = { ...user, password: res.data };
          return this.http
            .post<ApiResponse>(`${this.apiRoot}/users`, newUser, httpOptions)
            .pipe(
              tap(() =>
                this.logger.log(
                  `UID: ${this.uid} Created API User: ${user.email} Default Organization ID: ${user.defaultOrganizationId}`
                )
              ),
              catchError((err) => {
                this.logger.error(
                  `UID: ${this.uid} Create API User error ${JSON.stringify(
                    err
                  )}`
                );
                throw err;
              })
            );
        })
      );
  }

  createAdminApiUser(
    reqst: ApiAdminUserRequest
  ): Observable<ApiAdminUserResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
    };

    return this.http
      .post<ApiResponse>(`${this.apiRoot}/users/admin`, reqst, httpOptions)
      .pipe(
        tap(() =>
          this.logger.log(
            `UID: ${this.uid} Created Admin API User: ${reqst.email}`
          )
        ),
        map((res) => {
          return <ApiAdminUserResponse>res.data;
        }),
        catchError((err) => {
          this.logger.error(
            `UID: ${this.uid} Create Admin API User error ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  deleteUser(uid: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('uid', uid),
    };

    return this.http
      .delete<ApiResponse>(`${this.apiRoot}/users`, httpOptions)
      .pipe(
        tap(() => this.logger.log(`UID: ${this.uid} Deleted User: ${uid}`)),
        catchError((err) => {
          this.logger.error(
            `UID: ${this.uid} Delete User error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  addUserToGroup(uid: string, groupId: number): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('uid', uid).set('group_id', groupId),
    };

    return this.http
      .post<ApiResponse>(`${this.apiRoot}/groups/user`, null, httpOptions)
      .pipe(
        tap(() =>
          this.logger.log(
            `UID: ${this.uid} Added User ${uid} to group id ${groupId}`
          )
        ),
        catchError((err) => {
          this.logger.error(
            `UID: ${this.uid} Add User to group error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  removeUserFromGroup(uid: string, groupId: number): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('uid', uid).set('group_id', groupId),
    };

    return this.http
      .delete<ApiResponse>(`${this.apiRoot}/groups/user`, httpOptions)
      .pipe(
        tap(() =>
          this.logger.log(
            `UID: ${this.uid} Removed User ${uid} from group id ${groupId}`
          )
        ),
        catchError((err) => {
          this.logger.error(
            `UID: ${this.uid} Remove User from group error ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  changePassword(
    credentials: ChangePasswordCredential
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
    };
    return this.http
      .put<ApiResponse>(
        `${this.apiRoot}/users/password`,
        credentials,
        httpOptions
      )
      .pipe(
        tap(() =>
          this.logger.log(`UID: ${credentials.username} changed password.`)
        ),
        catchError((err) => {
          this.logger.error(
            `UID: ${
              credentials.username
            } change password error, original password was incorrect. ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  resetPasswordRequest(
    requestBody: ResetPasswordRequest
  ): Observable<ApiResponse> {
    const body = {
      type: 'sharescape',
      firestoreDatabaseId: environment.firebaseConfig.databaseId,
      ...requestBody,
    };
    return this.http
      .post<ApiResponse>(`${this.apiRoot}/users/password/reset`, body)
      .pipe(
        tap(() =>
          this.logger.log(`UID: ${body.username} sent reset password request.`)
        ),
        catchError((err) => {
          this.logger.error(
            `UID: ${
              body.username
            } reset password request error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  resetPassword(body: ResetPassword): Observable<ApiResponse> {
    return this.http
      .put<ApiResponse>(`${this.apiRoot}/users/password/reset`, body)
      .pipe(
        tap(() => this.logger.log(`User reset password. Token: ${body.token}`)),
        catchError((err) => {
          this.logger.error(
            `User password reset error. Token: ${body.token} ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  // An Admin user can reset a regular user's password.
  // This function is used during the Resend Portal invite flow.
  adminResetPassword(username: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
    };

    return this.http
      .get<ApiResponse>(
        `${this.apiRoot}/artemis/user-access/random-password`,
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          const body = { username, password: res.data };
          return this.http
            .post<ApiResponse>(
              `${this.apiRoot}/artemis/user-access/reset`,
              body,
              httpOptions
            )
            .pipe(
              tap(() =>
                this.logger.log(
                  `UID: ${this.uid} reset the password and sent a portal invite email for ${username}`
                )
              ),
              catchError((err) => {
                this.logger.error(
                  `UID: ${this.uid} admin password reset error ${JSON.stringify(
                    err
                  )}`
                );
                throw err;
              })
            );
        })
      );
  }

  toggleApiUserActive(user: Credential): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('username', user.id),
    };

    let url = `${this.apiRoot}/artemis/user-access/`;
    let message = `UID: ${this.uid} set user: ${user.id} `;
    if (user.isActive) {
      // Trying to enable user
      url = url + 'grant';
      message = message + `to Active.`;
    } else {
      // Trying to disable user
      url = url + 'revoke';
      message = message + `to Inactive.`;
    }
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap((res) => this.logger.log(message)),
      catchError((err) => {
        this.logger.error(
          `UID: ${this.uid} Set User Active error ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  getUserByEmail(email: string): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('username', email),
    };
    return this.http
      .get<ApiResponse>(`${this.apiRoot}/users/username/exists`, httpOptions)
      .pipe(
        catchError((err) => {
          this.logger.error(
            `UID: ${this.uid} getUserByEmail error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  filesUpload(
    files: File[],
    patientId: string,
    docType: string
  ): Observable<any> {
    return forkJoin(
      files.map((file) => {
        const fileName = file.name;
        const formData = new FormData();
        formData.append(fileName, file);

        // Do not specify a Content-Type so multi-part/form
        const headers = this.createApiTokenHeaders();

        const params = new HttpParams()
          .set('fileName', fileName)
          .set('contentType', file.type)
          .set('patientId', patientId)
          .set('docType', docType)
          .set('isActive', 'true');

        const httpOptions = {
          headers: headers,
          params: params,
        };

        return this.http.post(
          `${this.apiRoot}/artemis/file`,
          formData,
          httpOptions
        );
      })
    ).pipe(
      catchError((err) => {
        this.logger.error(
          `UID: ${this.uid} filesUpload error ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  sendInvite(user: UserCredential | ApiAdminUserResponse): Observable<any> {
    // Configure Header
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers,
    };

    // Create Body
    let portalInviteHTML = this.portalConfig.portalInviteHTML;
    portalInviteHTML = portalInviteHTML.replace(
      /PORTAL_NAME/g,
      this.portalConfig.name
    );
    portalInviteHTML = portalInviteHTML.replace(/USERNAME/g, user.email);
    portalInviteHTML = portalInviteHTML.replace(/PASSWORD/g, user.password);
    portalInviteHTML = portalInviteHTML.replace(
      /URL/g,
      `${window.location.origin}/login`
    );
    portalInviteHTML = portalInviteHTML.replace(
      /CONTACT/g,
      this.portalConfig.contact
    );

    const body = {
      recipients: [user.email],
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Invite`,
      body: portalInviteHTML,
      type: 'sharescape',
    };

    return this.http.post(`${this.apiRoot}/email/v2`, body, httpOptions);
  }

  sendAddOrgEmail(email: string): Observable<any> {
    // Configure Header
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers,
    };

    // Create Body
    let portalHTML = this.portalConfig.portalAddOrgHTML;
    portalHTML = portalHTML.replace(/PORTAL_NAME/g, this.portalConfig.name);
    portalHTML = portalHTML.replace(/URL/g, `${window.location.origin}/login`);

    const body = {
      recipients: [email],
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Invite`,
      body: portalHTML,
      type: 'sharescape',
    };

    return this.http.post(`${this.apiRoot}/email/v2`, body, httpOptions);
  }

  sendFeedback(user, feedback: any): Observable<any> {
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers,
    };

    let body = this.portalConfig.feedbackMessage;
    body = body.replace(/USER/g, user);
    body = body.replace(/FEEDBACK/g, feedback);

    const message = {
      recipients: this.portalConfig.feedbackRecipients,
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Feedback`,
      type: 'sharescape',
      body,
    };

    return this.http
      .post(`${this.apiRoot}/email/v2`, message, httpOptions)
      .pipe(tap(() => this.logger.log(`UID: ${user} provided feedback.`)));
  }

  importPatient(location: string, accountNo: string): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', accountNo)
        .set('location_id', location),
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/import`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap((resp) => {
        const pat: Patient = resp.data;
        this.logger.log(
          `UID: ${this.uid} Imported Patient - Account Number: ${accountNo} Location ID: ${location} Patient ID: ${pat.id}`
        );
      }),
      catchError((err) => {
        this.logger.error(
          `UID: ${this.uid} Import Patient failed. Account Number: ${accountNo} Location ID: ${location}`
        );
        throw err;
      })
    );
  }

  reImportPatient(patient: Patient): Observable<any> {
    const locationId = patient.id.split(':')[0];
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', patient.accountNo)
        .set('location_id', locationId),
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/import`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Imported Patient - Account Number: ${patient.accountNo} Location ID: ${locationId}`
        );
      }),
      catchError((err) => {
        this.logger.error(
          `UID: ${this.uid} Import Patient failed. Account Number: ${patient.accountNo} Location ID: ${locationId}`
        );
        throw err;
      })
    );
  }

  refreshPatient(patient: Patient): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
    };
    const parts = patient.id.split(':');
    const location_id = parts[0];
    const patient_id = parseInt(parts[1], 10);
    const body = {
      location_id,
      patient_id,
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/record-sync`;
    return this.http.post<ApiResponse>(url, body, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Refreshed Patient - Patient ID: ${patient.id}`
        );
      })
    );
  }

  refreshPatientDocuments(
    location: string,
    accountNo: string
  ): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', accountNo)
        .set('location_id', location),
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/docs/sync`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Refreshed Patient Documents - Account Number: ${accountNo} Location ID: ${location}`
        );
      })
    );
  }

  generatePatientDocument(
    location: string,
    accountNo: string,
    type: string
  ): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', accountNo)
        .set('location_id', location)
        .set('type', type),
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/docs/generate`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Generated Patient Document - Account Number: ${accountNo} Location ID: ${location} Type: ${type}`
        );
      })
    );
  }

  removePatient(patient: Patient): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('patient_id', patient.id),
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/clear`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Removed Patient - Patient ID: ${patient.id}`
        );
      })
    );
  }

  compilePDFs(documentIds: string[]): Observable<ApiResponse> {
    const headers = this.createApiTokenHeaders().set(
      'Content-Type',
      'application/json'
    );
    const httpOptions = { headers };
    const body = { documents: documentIds };

    return this.http
      .post<ApiResponse>(
        `${this.apiRoot}/artemis/docs/merge`,
        body,
        httpOptions
      )
      .pipe(
        tap(() => {
          this.logger.log(
            `UID: ${this.uid} compiled and downloaded ${documentIds.join()}`
          );
        })
      );
  }

  getFileUrl(path: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${this.apiRoot}${path}&authorization=${this.firebaseToken}`
    );
  }

  logout() {
    this.logger.log(`UID: ${this.uid} successfully logged out.`);
    // Sign out of Firebase
    signOut(this.fbAuth);
    // Return to homepage
    this.router.navigate(['login']);
  }

  createApiTokenHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', this.firebaseToken)
      .set('Entity-Type', this.entityType);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
