import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRoles } from '../auth/+state/auth.selectors';
import { State } from './app.reducer';

import { getRouterSelectors } from '@ngrx/router-store';

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();

export const getAppState = createFeatureSelector<State>('app');
export const selectStates = createSelector(
  getAppState,
  (state) => state.states
);

export const selectPrefix = createSelector(
  getAppState,
  (state) => state.prefixes
);
export const selectDocTypes = createSelector(
  getAppState,
  (state) => state.docTypes
);
export const selectDocumentGeneration = createSelector(
  getAppState,
  (state) => state.documentGeneration
);
export const selectTransactionTypes = createSelector(
  getAppState,
  (state) => state.transactionTypes
);
export const selectPhoneTypes = createSelector(
  getAppState,
  (state) => state.phoneTypes
);
export const selectTermsOfService = createSelector(
  getAppState,
  (state) => state.termsOfService
);
export const selectContact = createSelector(
  getAppState,
  (state) => state.contact
);
export const selectRequestAppointment = createSelector(
  getAppState,
  (state) => state.requestAppointment
);
export const selectPortalConfiguration = createSelector(
  getAppState,
  (state) => state.portalConfiguration
);
export const selectPortalConfigurationId = createSelector(
  getAppState,
  (state) => state.portalConfiguration.id
);

export const selectPrivacyPolicy = createSelector(
  getAppState,
  (state) => state.privacyPolicy
);

export const selectRedirectUrl = createSelector(
  getAppState,
  (state) => state.redirectUrl
);

export const selectApiRoot = createSelector(
  getAppState,
  (state) => state.apiRoot
);

export const selectOrganizationLogoUrl = createSelector(
  getAppState,
  (state) => state.organizationLogoUrl
);

export const selectEnvironment = createSelector(
  getAppState,
  (state) => state.environment
);

export const selectEntityType = createSelector(
  getAppState,
  (state) => state.entityType
);

export const selectPatientImport = createSelector(
  getAppState,
  selectRoles,
  (state, roles) => state.patientImport && roles.includes('admin')
);

export const selectPatientRefresh = createSelector(
  getAppState,
  selectRoles,
  (state, roles) => state.refreshPatient && roles.includes('admin')
);

export const selectPatientRemove = createSelector(
  getAppState,
  selectRoles,
  (state, roles) => state.removePatient && roles.includes('admin')
);

export const selectPatientUploadDocuments = createSelector(
  getAppState,
  selectRoles,
  (state, roles) => state.patientUploadDocuments && roles.includes('admin')
);

export const selectDocumentsRefresh = createSelector(
  getAppState,
  (state) => state.documentsRefresh
);

export const selectNavElements = createSelector(
  getAppState,
  (state) => state.navElements
);

export const selectDocumentsLayout = createSelector(
  getAppState,
  (state) => state.documentsLayout
);

export const selectResetPasswordHTML = createSelector(
  getAppState,
  (state) => state.resetPasswordHTML
);

export const selectCompiledPDFsRoot = createSelector(
  getAppState,
  (state) => state.compiledPDFsRoot
);

export const getResetPasswordHTML = createSelector(
  getAppState,
  selectApiRoot,
  (state, apiRoot) => state.resetPasswordHTML.replace('${apiRoot}', apiRoot)
);
export const selectThemeName = createSelector(
  getAppState,
  (state) => state.themeName
);

// Return the display columns for the passed in table and use the user's role
// to discriminate between the read only view and the admin view
export const selectDisplayedColumns = createSelector(
  getAppState,
  selectRoles,
  (state: State, roles: Array<string>, props: any) => {
    if (state && state.displayedColumns) {
      return state.displayedColumns && roles.includes('admin')
        ? state.displayedColumns[`${props.name}-admin`]
        : state.displayedColumns[props.name];
    }
  }
);
